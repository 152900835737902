import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const FbButton = makeShortcode("FbButton");
const Dont = makeShortcode("Dont");
const Link = makeShortcode("Link");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-buttons"
            }}>{`Writing buttons`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-links"
            }}>{`Writing links`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "writing-buttons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-buttons",
        "aria-label": "writing buttons permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Writing buttons`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#encourage-action">
        Encourage action
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#explain-whats-going-to-happen-next">
        Explain what's going to happen next
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#be-concise">
        Be concise
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#be-careful-with-cancel-actions">
        Be careful with cancel actions
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="encourage-action">Encourage action</h3>
    <p>{`Be as clear as possible with your button labels by using a strong, actionable verb followed by a noun.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">Save changes</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">Save</FbButton>
  </Dont>
    </Guideline>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">See details</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">Details</FbButton>
  </Dont>
    </Guideline>
    <h3 id="explain-what's-going-to-happen-next">
  Explain what's going to happen next
    </h3>
    <p>{`Use descriptive button labels so it’s clear what’s going to happen, or where the user is going to be taken, if the button is used.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">Add to basket</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">I want this!</FbButton>
  </Dont>
    </Guideline>
    <p>{`If a user doesn’t read any other content on the page, they should still be able to understand what the button does from the label alone, so avoid vague button labels.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">Place order</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">Continue</FbButton>
  </Dont>
    </Guideline>
    <h3 id="be-concise">Be concise</h3>
    <p>{`Make sure your button label fits on one line.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">Save changes</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">
      Save changes and
      <br />
      continue
    </FbButton>
  </Dont>
    </Guideline>
    <p>{`Avoid using ‘my’ or ‘your’ in button labels.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">Log in to account</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">Log in to your account</FbButton>
  </Dont>
    </Guideline>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">Track delivery</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">Track my delivery</FbButton>
  </Dont>
    </Guideline>
    <p>{`Start with a capital letter and use sentence case, but don’t add full stops.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">Go to trolley</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">GO TO TROLLEY.</FbButton>
  </Dont>
    </Guideline>
    <h3 id="be-careful-with-cancel-actions">Be careful with cancel actions</h3>
    <p>{`If a user is cancelling something, like an order, make it clear how they can confirm the cancellation with your button labels.`}</p>
    <p>{`Make it clear which button is confirmation and which is cancellation through your labels.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant='primary' isRounded mdxType="FbButton">Keep order</FbButton>
    <FbButton variant='primary' isRounded mdxType="FbButton">Cancel order</FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant='primary' isRounded mdxType="FbButton">Cancel</FbButton>
    <FbButton variant='primary' isRounded mdxType="FbButton">Confirm</FbButton>
  </Dont>
    </Guideline>
    <p>{`For general guidance on using buttons, `}<a parentName="p" {...{
        "href": "/components/button"
      }}>{`read our button component page`}</a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "writing-links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-links",
        "aria-label": "writing links permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Writing links`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#encourage-action-1">
        Encourage action
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#explain-whats-going-to-happen-next-1">
        Explain what's going to happen next
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#make-your-links-descriptive">
        Make your links descriptive
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#be-concise-1">
        Be concise
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#be-consistent">
        Be consistent
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="encourage-action-1">Encourage action</h3>
    <p>{`Use a verb to give the link a strong sense of action.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Link href="#" mdxType="Link">Go to stock checker</Link>
  </Do>
  <Dont mdxType="Dont">
    <Link href="#" mdxType="Link">Stock checker</Link>
  </Dont>
    </Guideline>
    <h3 id="explain-what's-going-to-happen-next-1">
  Explain what's going to happen next
    </h3>
    <p>{`Make sure that the link label makes it clear what content the link goes to.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Link href="#" mdxType="Link">View our opening hours</Link>
  </Do>
  <Dont mdxType="Dont">
    <Link href="#" mdxType="Link">Find out more</Link>
  </Dont>
    </Guideline>
    <h3 id="make-your-links-descriptive">Make your links descriptive</h3>
    <p>{`Don’t use vague or generic link text. Links shouldn’t need the supporting copy to make sense.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Link href="#" mdxType="Link">Read our terms and conditions</Link>
  </Do>
  <Dont mdxType="Dont">
    <span>
      To read our terms and conditions, <Link href="#" mdxType="Link">click here</Link>
    </span>
  </Dont>
    </Guideline>
    <h3 id="be-concise-1">Be concise</h3>
    <p>{`Keep links as short as possible while still making them clear.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Link href="#" mdxType="Link">Go to stock checker</Link>
  </Do>
  <Dont mdxType="Dont">
    <Link href="#" mdxType="Link">To stock checker</Link>
  </Dont>
    </Guideline>
    <p>{`Standalone links should start with a capital letter, and don’t need a full stop.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Link href="#" mdxType="Link">Read our terms and conditions</Link>
  </Do>
  <Dont mdxType="Dont">
    <Link href="#" mdxType="Link">read our terms and conditions.</Link>
  </Dont>
    </Guideline>
    <h3 id="be-consistent">Be consistent</h3>
    <p>{`Use the same link text for links that go to the same content. While try to use unique link text for links that go to different content.`}</p>
    <p>{`For general guidance on using links, `}<a parentName="p" {...{
        "href": "/components/link"
      }}>{`read our link component page`}</a>{`.`}</p>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help you answer any questions around copy or help with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      